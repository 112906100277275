import i18n from "@/i18n";

 export function getFilterStatus() {  
    return [
        {
          name: i18n.t('components.select-status-filter-widget.status.new'),
          icon: 'mdi-new-box',
          slug: 'new',
          className: 'status-new'
        },
        {
          name: i18n.t('components.select-status-filter-widget.status.active'),
          icon: 'mdi-motion-play-outline',
          slug: 'active',
          className: 'status-active'
        },
        {
          name: i18n.t('components.select-status-filter-widget.status.inactive'),
          icon: 'mdi-motion-pause-outline',
          slug: 'inactive',
          className: 'status-inactive'
        },
        {
          name: i18n.t('components.select-status-filter-widget.status.open'),
          icon: 'mdi-clock-outline',
          slug: 'open',
          className: 'status-open'
        },
        {
          name: i18n.t('components.select-status-filter-widget.status.planned'),
          icon: 'mdi-clock-outline',
          slug: 'planned',
          className: 'status-planned'
        },
        {
          name: i18n.t('components.select-status-filter-widget.status.processing'),
          icon: 'mdi-chat-processing-outline',
          slug: 'processing',
          className: 'status-processing'
        },
        {
          name: i18n.t('components.select-status-filter-widget.status.ready'),
          icon: 'mdi-read',
          slug: 'ready',
          className: 'status-ready'
        },
        {
          name: i18n.t('components.select-status-filter-widget.status.delivered'),
          icon: 'mdi-truck-check-outline',
          slug: 'delivered',
          className: 'status-delivered'
        },
        {
          name: i18n.t('components.select-status-filter-widget.status.completed'),
          icon: 'mdi-check-circle-outline',
          slug: 'completed',
          className: 'status-completed'
        },
        {
          name: i18n.t('components.select-status-filter-widget.status.finished'),
          icon: 'mdi-check-all',
          slug: 'finished',
          className: 'status-finished'
        },
        {
          name: i18n.t('components.select-status-filter-widget.status.cancelled'),
          icon: 'mdi-folder-cancel-outline',
          slug: 'cancelled',
          className: 'status-cancelled'
        },
        {
          name: i18n.t('components.select-status-filter-widget.status.approved'),
          icon: 'mdi-check-circle-outline',
          slug: 'approved',
          className: 'status-approved'
        },
        {
          name: i18n.t('components.select-status-filter-widget.status.declined'),
          icon: 'mdi-cancel',
          slug: 'declined',
          className: 'status-declined'
        },
        {
          name: i18n.t('components.select-status-filter-widget.status.deleted'),
          icon: 'mdi-trash-can-outline',
          slug: 'deleted',
          className: 'status-deleted'
        },
        {
          name: i18n.t('components.select-status-filter-widget.status.done'),
          icon: 'mdi-check-circle-outline',
          slug: 'done',
          className: 'status-done'
        },
        {
          name: i18n.t('components.select-status-filter-widget.status.submitted'),
          icon: 'mdi-ticket-confirmation-outline',
          slug: 'submitted',
          className: 'status-submitted'
        },
        {
          name: i18n.t('components.select-status-filter-widget.status.rejected'),
          icon: 'mdi-card-bulleted-off-outline',
          slug: 'rejected',
          className: 'status-rejected'
        },
        {
          name: i18n.t('components.select-status-filter-widget.status.cleared'),
          icon: 'mdi-format-clear',
          slug: 'cleared',
          className: 'status-cleared'
        },
        {
          name: i18n.t('components.select-status-filter-widget.status.exported'),
          icon: 'mdi-export',
          slug: 'exported',
          className: 'status-exported'
        },
        {
          name: i18n.t('components.select-status-filter-widget.status.filed'),
          icon: 'mdi-file-chart-check-outline',
          slug: 'filed',
          className: 'status-filed'
        },
        {
          name: i18n.t('components.select-status-filter-widget.status.reimbursed'),
          icon: 'mdi-account-arrow-up-outline',
          slug: 'reimbursed',
          className: 'status-reimbursed'
        },
        {
          name: i18n.t('components.select-status-filter-widget.status.invoiced'),
          icon: 'mdi-account-arrow-up-outline',
          slug: 'invoiced',
          className: 'status-invoiced'
        },
        {
          name: i18n.t('components.select-status-filter-widget.status.published'),
          icon: 'mdi-check-circle-outline',
          slug: 'published',
          className: 'status-published'
        },
        {
          name: i18n.t('components.select-status-filter-widget.status.archived'),
          icon: 'mdi-archive-check-outline',
          slug: 'archived',
          className: 'status-archieved'
        },
        {
          name: i18n.t('components.select-status-filter-widget.status.overdue'),
          icon: 'mdi-clock-alert-outline',
          slug: 'overdue',
          className: 'status-overdue'
        },
        {
          name: i18n.t('components.select-status-filter-widget.status.draft'),
          icon: 'mdi-pencil',
          slug: 'draft',
          className: 'status-draft'
        },
        {
          name: i18n.t('components.select-status-filter-widget.status.running'),
          icon: 'mdi-run',
          slug: 'running',
          className: 'status-running'
        },
    ];
}