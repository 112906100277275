<template>
  <div class="filter-date-range-picker" :class="{'extended-range-picker': date.length === 2 }">

    <v-menu
        v-model="menu"
        :close-on-content-click="false"
        transition="scale-transition"
        offset-y
        min-width="auto"
    >
      <template v-slot:activator="{ on, attrs }">
        <v-text-field
            :label="filterLabel"
            v-model="formattedDate"
            prepend-inner-icon="mdi-calendar"
            readonly
            filled
            v-bind="attrs"
            v-on="on"
            v-on:click:prepend-inner="menu = true"
            :clearable="clearable"
            :data-cy="dataCy"
            @click:clear="clear"
            hide-details="auto"
            :rules="required ? [(v) => !!v || $t('validation.required.default')] : []"
        ></v-text-field>
      
      </template>
      
      <v-date-picker
          range
          v-model="internalDate"
          scrollable
          locale="de-de"
          :first-day-of-week="1"
          @input="applyDate"
      ></v-date-picker>
    </v-menu>
  </div>
</template>
<script>
import { VDatePicker } from 'vuetify/lib';
import moment from 'moment'
import { EventBus } from './../../event-bus'

export default {
  name: "AppFilterDatePicker",
  extends: VDatePicker,
  props: {
    filterLabel: String,
    required: false,
    date: {
      required: true
    },
    dataCy: {
      default: ''
    },
    id: {
      required: true
    },
    clearable: Boolean,
  },
  model: {
    prop: 'date',
    event: 'date'
  },
  created() {
    this.internalDate = this.date;
  },
  data() {
    return {
      internalDate: null,
      menu: false,
    }
  },
  methods: {
    applyDate: function () {
      this.menu = false
      let msg = {
        newDate: this.internalDate,
        id: this.id
      }
      EventBus.$emit('filter-date-changed', msg)
      this.$emit('date', this.internalDate);
    },
    clear() {
      this.internalDate = null
      this.$emit('date', []);
    },
  },
  computed: {
    computedProps() {
      return {
        ...this.$props,
      }
    },
    formattedDate: {
      get() {
        return this.date && this.date.length > 0
        ? this.date.map(d => moment(d).format('DD.MM.YYYY')).join(' ~ ') : '';
      },
      set() {
        this.date ? moment(this.date) : ''
      }
    }
  }
}
</script>

<style scoped lang="scss">
.filter-date-range-picker{
  width: 180px;
  transition: width 0.3s;

  &.extended-range-picker{
    width: 265px;
  }
}
</style>
