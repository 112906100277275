<template>
  <div class="has-hero-container">
    <div>

      <toolbar :dataLoaded="true">{{ t('title') }}</toolbar>

      <div class="sp-hero sp-bg-light-blue">

        <v-container fluid class="hero-filter" v-on:keyup.enter="applyFilter(); options.page = 1">

          <app-filter-apply-button
              v-if="filterStore.hasChanges"
              @click="applyFilter(); options.page = 1"
          />

          <v-slide-group show-arrows>

            <v-slide-item>

              <div class="filter-item">
                <app-filter-date-picker
                    :filterLabel="$t('components.app-filter-date-picker.label.startdate')"
                    v-model="filterStore.filter.workStart"
                    id="dateStart"
                    :clearable="true"
                />
              </div>

            </v-slide-item>
            <v-slide-item>

              <div class="filter-item">
                <app-filter-date-picker
                    :filterLabel="$t('components.app-filter-date-picker.label.enddate')"
                    v-model="filterStore.filter.workEnd"
                    id="dateEnd"
                    :clearable="true"
                />
              </div>

            </v-slide-item>
            <v-slide-item>
              <div class="filter-item filter-search-list">
                <content-filter-object
                    v-model="filterStore.filter.selectedObject"
                />
              </div>
            </v-slide-item>
            <v-slide-item>
              <div class="filter-item filter-search-list">
                <app-filter-search-field
                    v-model="filterStore.filter.orderNumber"
                    :filterLabel="t('order-number')"
                    clearable
                    append-icon="mdi-magnify"
                />
              </div>
            </v-slide-item>

            <v-slide-item>
              <div class="filter-item filter-search-list">
                <user-filter-widget
                    v-model="filterStore.filter.selectedUser"
                    :includeInactive="true"
                />
              </div>
            </v-slide-item>
            <v-slide-item>
              <div class="filter-item filter-status-filter">
                <work-report-status-filter v-model="filterStore.filter.status"/>
              </div>
            </v-slide-item>
            <v-slide-item>
              <div class="filter-item">
                <v-checkbox
                    v-model="filterStore.filter.my_entries"
                    :label="$t('users.list.filter.my_entries')"
                />
              </div>
            </v-slide-item>
          </v-slide-group>

        </v-container>

      </div>

      <v-container fluid>
        <v-row class="data-table-toolbar">
          <v-spacer></v-spacer>
          <div>
            <v-btn
                elevation="0"
                color="primary"
                class="icon-left"
                data-cy="createButton"
                :to="{ path: '/work-reports/create'}"
            >
              <v-icon>mdi-plus</v-icon>
              {{ t('create') }}
            </v-btn>
          </div>
        </v-row>
        <v-row v-if="dataLoaded && items.length === 0">
          <div class="empty-datatable">
            <v-icon>mdi-alert-circle-outline</v-icon>
            {{ t('no-data') }}
          </div>
        </v-row>

        <v-row v-else>

          <v-data-table
              calculate-widths
              :headers="headers"
              :items="items"
              :options.sync="options"
              :server-items-length="totalItems"
              :loading="loading"
              class="elevation-0"
              :sort-by.sync="filterStore.table.sortBy"
              :sort-desc.sync="filterStore.table.sortDesc"
              :page.sync="filterStore.table.currentPage"
              :items-per-page.sync="filterStore.table.itemsPerPage"
              :footer-props="{
						showFirstLastPage: true,
						firstIcon: 'mdi-arrow-collapse-left',
						lastIcon: 'mdi-arrow-collapse-right',
						prevIcon: 'mdi-minus',
						nextIcon: 'mdi-plus',
						'items-per-page-options': [25, 50, 100, 200],
						pageText: '{0}-{1} von {2}',
						'items-per-page-text': t('table.items-per-page')
					}"
          >
            <v-progress-linear v-show="loading" slot="progress" color="primary" indeterminate></v-progress-linear>

            <template v-slot:[`item.object`]="{ item }">
              <span class="ellipsis"><b>{{ item.object }}</b></span>
            </template>


            <template v-slot:[`item.created_by`]="{ item }">
              <span class="ellipsis">{{ item.created_by.display_name }}</span>
            </template>

            <template v-slot:[`item.assigned_to`]="{ item }">
              <span class="ellipsis">{{ item.assigned_to.display_name }}</span>
            </template>

            <template v-slot:[`item.work_start`]="{ item }">
              <span>{{ formatDate(item.work_start) }}</span>
            </template>

            <template v-slot:[`item.work_end`]="{ item }">
              <span>{{ formatDate(item.work_end) }}</span>
            </template>

            <template v-slot:[`item.status`]="{ item }">
              <StatusIcons :status="item.status"/>
            </template>

            <template v-slot:[`item.edit`]="{ item }">

              <router-link :to="{ path: '/work-reports/' + item.id }" class="edit-row-table">
                <v-icon
                    small
                    class="edit-row-button"
                >
                  edit
                </v-icon>
              </router-link>
            </template>
            <template v-slot:[`item.export`]="{ item }">
              <v-btn :loading="processing == item.id" :disabled="processing == item.id" plain @click="doExport(item)">
                <v-icon small class="edit-row-button">mdi-export</v-icon>
              </v-btn>
            </template>
          </v-data-table>

        </v-row>

      </v-container>

    </div>

  </div>
</template>

<script>
import {HTTP} from './../auth'
import toolbar from '../components/layouts/Navigation.vue'
import AppFilterDatePicker from "../components/vuetify/AppFilterDatePicker"
import AppFilterSearchField from "../components/vuetify/AppFilterSearchField"


import moment from 'moment'
import ContentFilterObject from "@/components/filter-components/ContentFilterObject.vue";
import UserFilterWidget from "@/components/filter-components/UserFilterWidget";
import AppFilterApplyButton from "@/components/vuetify/AppFilterApplyButton";
import {useWorkReportFilterStore} from "@/stores/WorkReportFilterStore";
import WorkReportStatusFilter from "@/components/filter-components/WorkReportStatusFilter";
import {EventBus} from "@/event-bus";
import StatusIcons from "@/components/layouts/StatusIcons";


export default {
  name: 'WorkReportsIndex',
  components: {
    StatusIcons,
    WorkReportStatusFilter,
    ContentFilterObject,
    UserFilterWidget,
    AppFilterApplyButton,
    toolbar,
    AppFilterDatePicker,
    AppFilterSearchField,
  },
  setup() {
    const filterStore = useWorkReportFilterStore()
    return {filterStore}
  },
  data() {
    return {
      current: 'AppFilterDatePicker',
      configuration: null,
      loading: false,
      isDownloadingExport: false,
      isExporting: false,
      exportStats: {
        approved: null,
      },
      totalItems: 0,
      items: [],
      options: {},
      dataLoaded: false,
      message: null,
      error: false,
      cancelHTTP: null,
      errorCancel: null,
      processing: false
    }
  },
  created() {
  },
  computed: {
    headers() {
      return [
        {
          text: this.t('table.object'),
          align: 'start',
          sortable: true,
          value: 'object',
          cellClass: 'text-middle'

        },
        {
          text: this.t('table.order_number'),
          align: 'start',
          sortable: true,
          value: 'order_number',
          cellClass: 'text-middle'
        },
        {
          text: this.t('table.created_by'),
          value: 'created_by',
          sortable: true,
          width: 250,
          cellClass: 'text-middle'
        },
        {
          text: this.t('table.assigned_to'),
          value: 'assigned_to',
          sortable: true,
          width: 250,
          cellClass: 'text-middle'
        },
        {
          text: this.t('table.start'),
          value: 'work_start',
          sortable: true,
          width: 120,
          cellClass: 'text-middle'
        },
        {
          text: this.t('table.end'),
          value: 'work_end',
          sortable: true,
          width: 120,
          cellClass: 'text-middle'
        },
        {
          text: this.t('table.status'),
          value: 'status',
          width: 75,
          align: 'center',
          sortable: false,
          cellClass: 'text-middle'
        },
        {
          text: this.t('table.edit'),
          value: 'edit',
          sortable: false,
          align: 'center',
          width: 75,
          cellClass: 'text-middle'
        },
        {
          text: this.t('table.export'),
          value: 'export',
          sortable: false,
          align: 'center',
          width: 75,
          cellClass: 'text-middle'
        }
      ];
    },
  },
  watch: {
    options: {
      handler() {
        this.getDataFromApi()
      },
      deep: true,
    },
  },
  methods: {
    t: function (key) {
      return this.$t('workreport.workreports.list.' + key);
    },
    moment: function () {
      return moment();
    },
    applyFilter() {
      this.filterStore.filterApplied()
      this.reload()
    },
    reload() {
      this.items = []
      this.getDataFromApi()
    },
    getDataFromApi() {
      this.loading = true
      this.dataLoaded = false
      this.apiCall().then(data => {
        this.items = data.items
        this.totalItems = data.total
        this.loading = false
        this.dataLoaded = true
      })
    },
    async doExport(item) {
      this.processing = item.id
      const config = {
        headers: {'content-type': 'application/json'},
        responseType: 'arraybuffer'
      }

      try {
        let response = await HTTP.get('work-reports/pdf-export/' + item.id, config)

        let headers = response.headers
        let blob = new Blob([response.data], {type: headers['content-type']})
        let link = document.createElement('a')
        link.style = 'display: none'
        link.href = window.URL.createObjectURL(blob)
        link.download = 'Report_' + item.object.replace(/[^a-z0-9]/gi, '') + '.pdf'
        document.body.appendChild(link)
        link.click()
        setTimeout(function () {
          document.body.removeChild(link)
          window.URL.revokeObjectURL(blob)
        }, 100)
      } finally {
        this.isDownloadingExcel = false
        this.processing = 0;
      }
    },
    apiCall() {
      return new Promise((resolve, reject) => {
        const {sortBy, sortDesc, page, itemsPerPage} = this.options

        let url = this.prepareUrl(sortBy, sortDesc, page, itemsPerPage)

        HTTP.get('work-reports' + url).then(function (response) {
          let items = response.data.data
          let total = response.data.total

          resolve({
            items,
            total,
          })
        }.bind(this))

      })
    },
    prepareUrl(sortBy, sortDesc, page, itemsPerPage) {
      let queryStringParts = []
      if (this.filterStore.filter.workStart) {
        queryStringParts.push('dateStart=' + this.filterStore.filter.workStart)
      }
      if (this.filterStore.filter.workEnd) {
        queryStringParts.push('dateEnd=' + this.filterStore.filter.workEnd)
      }
      if (this.filterStore.filter.orderNumber) {
        queryStringParts.push('onr=' + this.filterStore.filter.orderNumber)
      }
      if (this.filterStore.filter.selectedUser) {
        queryStringParts.push('q=' + this.filterStore.filter.selectedUser?.id)
      }
      if (this.filterStore.filter.selectedObject) {
        queryStringParts.push('o=' + this.filterStore.filter.selectedObject)
      }
      if (this.filterStore.filter.status.length > 0) {
        queryStringParts.push('st=' + this.filterStore.filter.status)
      }

      if (this.filterStore.filter.my_entries) {
        queryStringParts.push('my_entries=true')
      }

      if (sortBy) {
        queryStringParts.push('sortBy=' + (sortBy[0] ?? ''))
        let sort = sortDesc[0] ? 'desc' : 'asc'
        queryStringParts.push('sortDesc=' + sort)
      }
      page = page ? page : 1
      queryStringParts.push('page=' + page)
      queryStringParts.push('itemsPerPage=' + itemsPerPage)
      return '?' + queryStringParts.join('&')
    }
  }
}
</script>
